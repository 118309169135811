import { Helmet } from 'react-helmet';
import { isPrerender } from '@medifind/utils';

const PageHead = () => {
  const description =
    'When it comes to your health, nothing is more valuable than time. Quickly find the best doctors, latest medical advances, and active clinical trials with MediFind. Our goal is to dramatically reduce the amount of time and frustration you spend finding the right healthcare, so you have the best possible chances at the best health outcomes.';
  return (
    <Helmet defer={!isPrerender}>
      <meta name="robots" content="index,follow,noarchive" />
      <meta name="googlebot" content="index,follow,noarchive" />
      <title>MediFind | Better Care. Faster.</title>
      <meta name="description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="MediFind | Better Care. Faster." />
      <meta property="og:locale" content="en_US" />
      <meta property="og:site_name" content="MediFind" />
      <meta property="og:url" content={process.env.NX_LOCAL_HOST} />
      <meta property="og:image" content={`${process.env.NX_LOCAL_HOST}/static/images/medifind-social-2020-05-12.png`} />
      <meta property="og:image:width" content="1024" />
      <meta property="og:image:height" content="684" />
      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'http://schema.org',
          '@type': 'Organization',
          url: `${process.env.NX_LOCAL_HOST}`,
          logo: `${process.env.NX_LOCAL_HOST}/static/images/logo-128.png`,
          description: description,
          name: 'MediFind',
          foundingDate: '2020',
          sameAs: [
            'https://www.facebook.com/medifindhealth/',
            'https://www.instagram.com/medifindhealth',
            'https://www.youtube.com/channel/UCbYmcsLXSFl0EkBdsTZ0nHQ',
            'https://www.linkedin.com/company/medifind',
            'https://www.twitter.com/medifindhealth',
          ],
        })}
      </script>
    </Helmet>
  );
};

export default PageHead;
