import { throttle } from 'lodash';

// try to handle passive events
let passiveEventSupported = false;
try {
  const opts = Object.defineProperty({}, 'passive', {
    get() {
      passiveEventSupported = true;
    },
  });
  window.addEventListener('test', null, opts);
} catch (e) {}
// if they are supported, setup the optional params
// FALSE doubles as the default CAPTURE value

const passiveEvent = passiveEventSupported ? { capture: false, passive: true } : false;

export const addPassiveListener = (type, callback, timeout = 300) => {
  const throttledCallback = timeout > 0 ? throttle(callback, timeout) : callback;
  window.addEventListener(type, throttledCallback, passiveEvent);
  return () => {
    window.removeEventListener(type, throttledCallback, passiveEvent);
  };
};
