import PropTypes from 'prop-types';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

const initialState = {
  bookmark: {},
  share: {},
};
const storeName = 'shareBookmark';
export const useShareBookmark = create(
  devtools(
    (set) => ({
      ...initialState,
      set,
      reset: () => set({ ...initialState }, false, { type: 'reset' }),
      getInitialState: () => ({ ...initialState }),
    }),
    {
      name: `${process.env.NX_APP}`,
      store: storeName,
    },
  ),
);

export const setShareBookmarkData = ({ bookmark, share }) => {
  useShareBookmark.setState({ bookmark, share }, true, {
    type: 'setShareBookmarkData',
  });
};

setShareBookmarkData.propTypes = {
  bookmark: PropTypes.shape({
    type: PropTypes.oneOf([
      'specialty',
      // 'article',
      'doctor',
      'recent',
      'publication',
      'trial',
      'condition',
      'procedure',
      'drug',
      'symptom-checker',
      'trial-search',
      'second-opinion',
      'find-specialist',
      'find-doctor',
      'page',
      'symptom',
      'organization',
    ]).isRequired,
    personifyId: PropTypes.number,
    projectId: PropTypes.number,
    codingSlug: PropTypes.string,
    codingName: PropTypes.string,
    bookmarkName: PropTypes.string,
    bookmarkId: PropTypes.string,
    data: PropTypes.any,
  }),
  share: PropTypes.shape({
    type: PropTypes.oneOf([
      'page',
      'specialty',
      // 'article',
      'doctor',
      'recent',
      'publication',
      'trial',
      'condition',
      'procedure',
      'drug',
      'symptom-checker',
      'trial-search',
      'second-opinion',
      'news',
      'symptom',
      'organization',
    ]).isRequired,
    data: PropTypes.any,
    permaLinkURL: PropTypes.string,
  }),
};
export const clearShareBookmarkData = () => {
  useShareBookmark.setState({ ...initialState }, false, {
    type: 'clearShareBookmarkData',
  });
};
