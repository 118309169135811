import { clientFetch } from '../clientFetch';

export const postBookmark = (params) => {
  return clientFetch(`/user/bookmark`, { method: 'POST', body: params });
};

export const getBookmark = (params) => {
  return clientFetch(`/user/bookmark`, { params });
};

export const deleteBookmark = (id) => {
  return clientFetch(`/user/bookmark`, { method: 'DELETE', body: { id } });
};

export const getBookmarkAdditionalData = (body) => {
  return clientFetch(`/user/bookmark/data`, { method: 'POST', body });
};
