import { Suspense, lazy } from 'react';
import { useNotificationCenter } from '@medifind/zustand';
import styles from './Notification.module.scss';
const Notification = lazy(() => import('./Notification'));

const NotificationCenter = () => {
  const { queue } = useNotificationCenter();
  return (
    <div className={styles['notification-container']}>
      {queue.map((notification) => (
        <Suspense fallback={<div className="fallback" />} key={notification.id}>
          <Notification {...notification} />
        </Suspense>
      ))}
    </div>
  );
};
export { NotificationCenter as default, NotificationCenter };
