import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { Debounce } from '@medifind/hooks';
import { deleteBookmark, getBookmark, postBookmark } from '@medifind/interface';
import { eventDispatch } from '@medifind/utils';
import { migrateReduxSessionStorageValue, sessionStorageMF } from '../utils';

const debounce = new Debounce(300).debounce;

const initialState = {
  bookmarks: {},
};
const storeName = 'bookmarks';
export const useBookmarks = create(
  devtools(
    persist(
      (set) => ({
        ...initialState,
        set,
        reset: () => set({ ...initialState }, false, { type: 'reset' }),
        getInitialState: () => ({ ...initialState }),
      }),
      {
        name: `${process.env.NX_APP}-${storeName}`,
        version: 0,
        storage: createJSONStorage(() => sessionStorageMF),
        onRehydrateStorage: () => (state) => {
          migrateReduxSessionStorageValue({ state, key: 'bookmarks' });
        },
      },
    ),
    {
      name: process.env.NX_APP,
      store: storeName,
    },
  ),
);

export const loadBookmarks = () => {
  return getBookmark()
    .then((bookmarks) => {
      const bMap = bookmarks.results.reduce((m, bookmark) => {
        m[bookmark.type] = m[bookmark.type] || [];
        m[bookmark.type].push(bookmark);
        return m;
      }, {});
      useBookmarks.setState({ bookmarks: bMap }, false, {
        type: 'loadBookmarks',
      });
    })
    .catch(() => {
      useBookmarks.setState({ bookmarks: {} }, false, {
        type: 'loadBookmarksError',
      });
    });
};

export const addBookmark = async (bookmark) => {
  eventDispatch('bookmarkTooltip');
  return debounce(() =>
    postBookmark(bookmark)
      .then((ret) => {
        if (ret.success) {
          const bookmarkToAdd = ret.bookmark;
          const bookmarks = useBookmarks.getState().bookmarks;
          const newBookmarks = { ...(bookmarks || {}) };
          newBookmarks[bookmarkToAdd.type] = [...(newBookmarks[bookmarkToAdd.type] || [])];
          newBookmarks[bookmarkToAdd.type].unshift(bookmarkToAdd);
          useBookmarks.setState({ bookmarks: newBookmarks }, false, {
            type: 'addBookmark',
          });
        }
      })
      .catch(() => {
        loadBookmarks();
      }),
  );
};

export const removeBookmark = async (bookmark) => {
  return debounce(() =>
    deleteBookmark(bookmark.id)
      .then(() => {
        const bookmarks = useBookmarks.getState().bookmarks;
        const newBookmarks = { ...(bookmarks || {}) };
        newBookmarks[bookmark.type] = [...(newBookmarks[bookmark.type] || [])];
        newBookmarks[bookmark.type] = newBookmarks[bookmark.type].filter((b) => b.id !== bookmark.id);
        useBookmarks.setState({ bookmarks: newBookmarks }, false, {
          type: 'removeBookmark',
        });
      })
      .catch(() => {
        loadBookmarks();
      }),
  );
};

export const clearBookmarkStore = () => {
  useBookmarks.setState({ ...initialState }, false, {
    type: 'clearBookmarkStore',
  });
};

export const selectOrgBookmarkData = (state) => state.bookmarks?.organization?.map((d) => d.data);
export const selectDoctorBookmarkData = (state) => state.bookmarks?.doctor?.map(({ data }) => data);
