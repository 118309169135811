// localStorageMF / sessionStorageMF are shims for issue below.
// zustand persist drops state currently unable to set store state within onRehydrateStorage.
// as of zustand@4.3.5
// https://github.com/pmndrs/zustand/pull/1689

export const localStorageMF = {
  getItem: async (name) => localStorage.getItem(name),
  setItem: async (name, value) => localStorage.setItem(name, value),
  removeItem: async (name) => localStorage.removeItem(name),
};

export const sessionStorageMF = {
  getItem: async (name) => sessionStorage.getItem(name),
  setItem: async (name, value) => sessionStorage.setItem(name, value),
  removeItem: async (name) => sessionStorage.removeItem(name),
};

export const migrateReduxLocalStorageValue = ({ state, key = '' }) => {
  const reduxStorage = JSON.parse(localStorage.getItem('persist:root')) || {};
  const reduxValue = reduxStorage[key];
  if (reduxValue) {
    const valueObj = JSON.parse(reduxValue);
    delete reduxStorage[key];
    localStorage.setItem('persist:root', JSON.stringify(reduxStorage));
    return state.set(valueObj);
  }
};

export const migrateReduxSessionStorageValue = ({ state, key = '' }) => {
  const reduxStorage = JSON.parse(sessionStorage.getItem('persist:session')) || {};
  const reduxValue = reduxStorage[key];
  if (reduxValue) {
    const valueObj = JSON.parse(reduxValue);
    delete reduxStorage[key];
    sessionStorage.setItem('persist:session', JSON.stringify(reduxStorage));
    return state.set(valueObj);
  }
};

export const migrateReduxSessionStorageFilterValue = ({ state, key = '' }) => {
  const reduxStorage = JSON.parse(sessionStorage.getItem('persist:session')) || {};
  const reduxFiltersStorage = reduxStorage.filters ? JSON.parse(reduxStorage.filters) : {};
  if (reduxFiltersStorage) {
    const reduxValue = reduxFiltersStorage[key];
    if (reduxValue) {
      delete reduxFiltersStorage[key];
      reduxStorage.filters = JSON.stringify(reduxFiltersStorage);
      sessionStorage.setItem('persist:session', JSON.stringify(reduxStorage));
      return state.set(reduxValue);
    }
  }
};

export const migrateReduxSessionStorageStepper = ({ state, stepper = '' }) => {
  const reduxStorage = JSON.parse(sessionStorage.getItem('persist:session')) || {};
  const reduxStepperStorage = reduxStorage.stepper ? JSON.parse(reduxStorage.stepper) : {};
  if (reduxStepperStorage) {
    const reduxValue = reduxStepperStorage[stepper];
    if (reduxValue) {
      delete reduxStepperStorage[stepper];
      reduxStorage.stepper = JSON.stringify(reduxStepperStorage);
      sessionStorage.setItem('persist:session', JSON.stringify(reduxStorage));
      return state.set(reduxValue);
    }
  }
};
