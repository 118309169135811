// Matches normalizeNumbers in api numbers.js keep them in sync
export const normalizeNumbers = (data) => {
  if (data == null || typeof data === 'number') {
    return data;
  } else if (Array.isArray(data)) {
    return [...data].map(normalizeNumbers);
  } else if (typeof data === 'string') {
    if (data === 'true') return true;
    else if (data === 'false') return false;
    if (isNaN(data) || data === '' || data[0] === '+') return data;
    else if (data.includes('.')) {
      if (data.indexOf('0') === 0 && data.indexOf('0.') !== 0) return data; // Check if float is a string, leading zero
      return parseFloat(data);
    } else {
      if (data.indexOf('0') === 0 && data.length >= 2) return data; // Check if int is a string, leading zero
      return parseInt(data, 10);
    }
  } else if (typeof data === 'object' && data !== null) {
    data = { ...data }; // Copy so we dont modify the original data
    Object.keys(data).forEach((k) => {
      data[k] = normalizeNumbers(data[k]);
    });
    return data;
  } else {
    return data;
  }
};

export const normalizeStrings = (data) => {
  if (data == null) {
    return data;
  } else if (Array.isArray(data)) {
    return data.map(normalizeStrings);
  } else if (typeof data === 'string') {
    return decodeURIComponent(data);
  } else if (typeof data === 'object' && data !== null) {
    Object.keys(data).forEach((k) => {
      data[k] = normalizeStrings(data[k]);
    });
    return data;
  } else {
    return data;
  }
};
