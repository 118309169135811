import { useEffect, useState } from 'react';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

const storeName = 'app-version';
export const useAppVersion = create(
  devtools(
    (set) => ({
      appVersion: document.querySelector('meta[name="version"]')?.content,
      appUpdated: false,
    }),
    {
      name: `${process.env.NX_APP}`,
      store: storeName,
    },
  ),
);

export const useAppVersionPoll = ({ pollInterval = 30000, disable = false }) => {
  const next = () => setTimeout(getVersion, pollInterval);
  const [versionData, setVersionData] = useState();
  const { appVersion } = useAppVersion((state) => state);

  const getVersion = () =>
    window
      .fetch('/version', {
        headers: {
          'Content-Type': 'text/plain',
        },
      })
      .then(async (res) => {
        if (!res.ok) {
          next();
          return;
        }
        setVersionData({ res, newVersion: (await res.text())?.trim() });
        next();
      })
      .catch(() => next());

  useEffect(() => {
    if (!disable) next();
  }, [disable]);

  useEffect(() => {
    if (versionData) {
      const newVersion = versionData.newVersion;
      if (appVersion != null && appVersion != newVersion) {
        useAppVersion.setState({ appVersion: newVersion, appUpdated: true }, true);
      } else {
        useAppVersion.setState({ appVersion: newVersion }, false);
      }
    }
  }, [versionData]);

  // return useAppVersion();
};
