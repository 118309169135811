import { SVG } from './SVG';
const IconCloseButton = ({ className, style, width, height, color = 'white', background = '#4062bb' }) => {
  const borderRadius = Math.max(width, height);
  return (
    <div
      style={{
        background,
        boxShadow:
          '0px 9.67509px 11.9971px rgba(0, 0, 0, 0.0828093), 0px 5.38772px 6.68077px rgba(0, 0, 0, 0.0671794), 0px 2.31722px 2.87335px rgba(0, 0, 0, 0.0464984)',
        borderRadius,
        width,
        minWidth: width + 'px',
        height,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...style,
      }}
      className={className}
    >
      <SVG width={(12 / 42) * width} height={(12 / 42) * height} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.6558 10.0363L7.86845 6.24895L11.6558 2.46156C12.1014 2.01598 12.1014 1.28294 11.6558 0.837362C11.2103 0.391786 10.4772 0.391786 10.0317 0.837362L6.24426 4.62476L2.45687 0.837362C2.01129 0.391786 1.33574 0.327106 0.832671 0.837362C0.322415 1.34762 0.401469 2.03036 0.832671 2.46156L4.62006 6.24895L0.832671 10.0363C0.351162 10.5179 0.372722 11.2078 0.832671 11.6605C1.29262 12.1205 2.01129 12.1061 2.45687 11.6605L6.24426 7.87315L10.0317 11.6605C10.4772 12.1061 11.2103 12.1061 11.6558 11.6605C12.1086 11.215 12.1086 10.4819 11.6558 10.0363Z"
          fill={color}
        />
      </SVG>
    </div>
  );
};

/* eslint-disable react/display-name */
export { IconCloseButton as default, IconCloseButton };
