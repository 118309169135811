import { Suspense, lazy, useState } from 'react';
import classNames from 'classnames';
import { useLazyComponentPreload } from '@medifind/hooks';
import { useFocusTrap } from '@medifind/hooks';
import { IconAvatar, IconLogo } from '@medifind/icons';
import { IconHamburger } from '@medifind/icons';
import { IconSearch } from '@medifind/icons';
import { IconCloseButton } from '@medifind/icons';
import { useRouter } from '@medifind/router';
import { Button } from '@medifind/shared-basic-components';
import { MFLink } from '@medifind/shared-basic-components';
import { EventListener } from '@medifind/utils';
import { logEventGA4, setAuthRedirect, setHeaderVisibility, useAuthentication } from '@medifind/zustand';
import MenuPopdown from './menu/MenuPopdown';
import styles from './Header.module.scss';
const Popover = lazy(() => import('@medifind/lazy-loadable/lib/popups/LazyPopover'));
const AuthMenu = lazy(() => import('./menu/AuthMenu'));
const MainMenu = lazy(() => import('./menu/MainMenu'));
const SearchMenu = lazy(() => import('./menu/SearchMenu'));

const activeStateFields = { menuActive: false, searchActive: false, profileActive: false };
const Header = () => {
  useLazyComponentPreload([AuthMenu, MainMenu, SearchMenu, Popover]);
  const router = useRouter();
  const [active, setActive] = useState({ ...activeStateFields });
  const [didShow, setDidShow] = useState(null);
  const [event, setEvent] = useState(null);
  const { profile } = useAuthentication();
  const { menuActive, searchActive, profileActive } = active;
  const [trapRef] = useFocusTrap();

  const darkMode = router.location.pathname === '/' && !(menuActive || searchActive || profileActive);

  const log = (category, action, type = router.location.pathname) => {
    logEventGA4('navigation', { action: action });
  };
  const activate = (params) => {
    setDidShow(true);
    setHeaderVisibility(true);
    setActive(params);
  };
  const deactivate = (params) => {
    setHeaderVisibility(false);
    setActive(params);
    setEvent(null);
  };
  const activateSearch = (event) => {
    setEvent(event);
    activate({ ...activeStateFields, searchActive: true });
    window.scrollTo(0, 0);
  };
  const activateAuth = (event) => {
    setEvent(event);
    setAuthRedirect(event.redirectAction, event.redirectData);
    activate({ ...activeStateFields, profileActive: true });
    window.scrollTo(0, 0);
  };

  const openMenu = () => {
    log('Navigation', 'Menu Opened');
    activate({ ...activeStateFields, menuActive: true });
  };

  const closeMenu = () => {
    log('Navigation', 'Menu Closed');
    deactivate({
      menuActive: false,
    });
  };
  const openSearch = () => {
    log('Navigation', 'Search Menu Opened');
    activate({ ...activeStateFields, searchActive: true });
  };
  const closeSearch = () => {
    log('Navigation', 'Search Menu Opened');
    deactivate({ searchActive: false });
  };
  const openProfile = () => {
    log('Navigation', 'Profile Menu Opened');
    activate({ ...activeStateFields, profileActive: true });
  };
  const closeProfile = () => {
    log('Navigation', 'Profile Menu Opened');
    deactivate({ profileActive: false });
  };
  const closeButton = (onClick) => {
    return (
      <Button
        className={styles['btn']}
        id="main-menu-button"
        onClick={onClick}
        focus="background"
        screenReaderText="Click to close menu"
        icon={<IconCloseButton width={32} height={32} />}
      />
    );
  };
  const closeAll = () => {
    deactivate({ ...activeStateFields });
  };

  const trapProp = profileActive || menuActive || searchActive ? { ref: trapRef } : {};

  return (
    <div className={styles['header-container']} {...trapProp}>
      <header className={classNames(styles['header'])}>
        <EventListener eventName="activateSearch" onEvent={activateSearch} />
        <EventListener eventName="activateAuth" onEvent={activateAuth} />
        <nav aria-label="Primary" className={styles['header-nav']}>
          <div className={styles['logo-container']}>
            <MFLink to="/" onClick={closeMenu} aria-label="Home">
              <IconLogo darkMode={darkMode} className={styles['logo']} />
            </MFLink>
          </div>
          <ul className={styles['right']}>
            <li className={styles['header-button']}>
              {!profileActive ? (
                <Button
                  focus="background"
                  id="toggle-profile"
                  onClick={openProfile}
                  className={styles['btn']}
                  screenReaderText="Click to view your profile"
                  icon={
                    profile ? (
                      <span className={styles['initial']}>{profile.givenName.substring(0, 1)}</span>
                    ) : (
                      <IconAvatar color={darkMode ? '#FEFEFE' : '#4062BB'} />
                    )
                  }
                />
              ) : (
                closeButton(closeProfile)
              )}
            </li>
            <li className={styles['header-button']}>
              {!searchActive ? (
                <Button
                  focus="background"
                  className={styles['btn']}
                  onClick={openSearch}
                  id="toggle-search"
                  screenReaderText="Click to search for condition or expert"
                  icon={<IconSearch color={darkMode ? '#FEFEFE' : '#4062BB'} />}
                />
              ) : (
                closeButton(closeSearch)
              )}
            </li>
            <li className={styles['header-button']}>
              {!menuActive ? (
                <Button
                  focus="background"
                  className={styles['btn']}
                  id="main-menu-button"
                  onClick={openMenu}
                  screenReaderText="Click to open main menu"
                  icon={<IconHamburger color={darkMode ? '#FEFEFE' : '#4062BB'} />}
                />
              ) : (
                closeButton(closeMenu)
              )}
            </li>
          </ul>
        </nav>
      </header>

      <div className={styles['menu-overlay']}>
        <MenuPopdown isActive={profileActive}>
          {didShow && (
            <Suspense fallback={<div className="fallback" />}>
              <AuthMenu onClose={closeProfile} isActive={profileActive} event={event} />
            </Suspense>
          )}
        </MenuPopdown>
        <MenuPopdown isActive={menuActive}>
          {didShow && (
            <Suspense fallback={<div className="fallback" />}>
              <MainMenu onClose={closeMenu} isActive={menuActive} event={event} />
            </Suspense>
          )}
        </MenuPopdown>
        <MenuPopdown isActive={searchActive}>
          {didShow && (
            <Suspense fallback={<div className="fallback" />}>
              <SearchMenu onClose={closeSearch} isActive={searchActive} event={event} />
            </Suspense>
          )}
        </MenuPopdown>
      </div>
      {profile && (
        <div className={styles['popover-container']}>
          <Suspense fallback={<div className="fallback" />}>
            <Popover
              text="Bookmark Saved Here"
              timeout={5000}
              eventName="bookmarkTooltip"
              debounceTimeout={10000}
              className={styles['popover-container--profile']}
            />
          </Suspense>
        </div>
      )}
      {/* <NotificationTrigger text={pendingEmailVerificationCopyText} eventName="pendingEmailVerification" maxWidth={'800px'} />
       */}
      {(profileActive || menuActive || searchActive) && <div className={styles.backdrop} onClick={closeAll}></div>}
    </div>
  );
};
export default Header;
