import { useUUID } from '@medifind/router';
export const SVG = ({ children, alt, ...props }) => {
  const id = useUUID();
  return (
    <svg {...(alt ? { 'aria-labelledby': id } : {})} {...props}>
      {alt ? <title id={id}>{alt}</title> : null}
      {children}
    </svg>
  );
};
