import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { pathToRegexp } from 'path-to-regexp';
import queryStringParse from 'querystring/decode';
import { Router as WRouter, useRouter as useWouter } from 'wouter';
import makeCachedMatcher from 'wouter/matcher';
import { listen } from './listen';
import { normalizeNumbers, normalizeStrings } from './normalize';
import { useLocation } from './useLocation';
import { useBackWithConfirmation, useLocationWithConfirmation } from './useLocationWithConfirmation';

export const RouteContext = createContext();
export const withRouter = (Component) => {
  const Wrapper = (props) => {
    const router = useRouter();

    return <Component {...props} router={router} />;
  };

  return Wrapper;
};
const convertPathToRegexp = (path) => {
  const keys = [];

  // we use original pathToRegexp package here with keys
  const regexp = pathToRegexp(path, keys);
  return { keys, regexp };
};
const customMatcher = makeCachedMatcher(convertPathToRegexp);
export const useRouter = () => {
  const router = useWouter();
  const goBack = useBackWithConfirmation();
  const [wouterLocation, location, navigate] = useLocation();
  const params = useContext(RouteContext);
  const [pageChange, setPageChange] = useState(null);
  useEffect(() => {
    const unListen = listen(setPageChange);
    return unListen;
  }, []);
  // navigate('/terms');
  return useMemo(() => {
    const queryParams = normalizeNumbers(queryStringParse(location.search.replace(/^\?/, '')));
    const pathParams = normalizeStrings(normalizeNumbers(params));

    return {
      ...router,
      wouterLocation,
      location,
      history: {
        push: (url) => navigate(url),
        replace: (url) => navigate(url, { replace: true }),
        goBack: goBack,
      },
      navigate,
      // match,
      pathParams,
      queryParams,
      customMatcher,
      query: { ...queryParams, ...pathParams },
    };
  }, [location.href, params, router, wouterLocation, pageChange]);
};

export const Router = ({ children }) => {
  return (
    <WRouter matcher={customMatcher} hook={useLocationWithConfirmation}>
      {children}
    </WRouter>
  );
};
