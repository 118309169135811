import classNames from 'classnames';
import PropTypes from 'prop-types';
import headerStyles from './HeaderMenuShared.module.scss';

const MenuPopdown = ({ children, isActive = false }) => {
  const menuClass = classNames(headerStyles['header-menu'], {
    [headerStyles['active']]: isActive,
  });
  return (
    <div className={menuClass} {...(!isActive ? { 'aria-hidden': 'true', tabIndex: -1, style: { visibility: 'hidden' } } : {})}>
      {children}
    </div>
  );
};
MenuPopdown.propTypes = {
  isActive: PropTypes.bool,
  children: PropTypes.any,
};
export default MenuPopdown;
