import jwtDecode from 'jwt-decode';
import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { fetchProfile, getIsUserVerified, refreshToken, setAuthorizationHeader, signout } from '@medifind/interface';
import { broadcastMessageServiceWorker, registerBroadcastListener } from '@medifind/utils';
import { setAccount } from '../account';
import { loadBookmarks } from '../bookmarks';
import { refreshGA4Config } from '../google';
import { localStorageMF, migrateReduxLocalStorageValue } from '../utils';

const initialState = {
  profile: null,
  decodedToken: null,
  token: null,
  loginCount: 0,
  sudo: null,
};

const storeName = 'authentication';
export const useAuthentication = create(
  devtools(
    persist(
      (set) => ({
        ...initialState,
        set,
        reset: () => set({ ...initialState }, false, { type: 'reset' }),
        getInitialState: () => ({ ...initialState }),
      }),
      {
        name: `${process.env.NX_APP}-${storeName}`,
        version: 0,
        storage: createJSONStorage(() => localStorageMF),
        onRehydrateStorage: () => (state) => {
          migrateReduxLocalStorageValue({ state, key: 'authentication' });
          if (state.decodedToken?.exp && state.decodedToken.exp <= new Date().getTime() / 1000) {
            // Remove the jwt if its expired
            state.set({ profile: null, decodedToken: null, token: null });
          } else {
            if (state.token) setAuthorizationHeader(state.token);
          }
        },
      },
    ),
    {
      name: `${process.env.NX_APP}`,
      store: storeName,
    },
  ),
);
export const isValidToken = (decoded) => {
  return decoded?.exp > Math.floor(new Date().getTime() / 1000) + 60;
};

export const refreshProfile = async () => {
  return fetchProfile()
    .then((profile) => useAuthentication.setState({ profile }))
    .catch(() => ({}));
};

const _authenticate = async (token, decodedToken) => {
  setAuthorizationHeader(token);
  if (decodedToken.account) {
    setAccount(decodedToken);
  }
  await checkAuthTokenRefresh();
  await loadBookmarks();
  return await refreshProfile().then((arg) => {
    refreshGA4Config();
    return arg;
  });
};
const broadcastState = () => {
  const { profile, decodedToken, token, loginCount } = useAuthentication.getState();
  broadcastMessageServiceWorker({ authenticated: !!profile, state: { profile, decodedToken, token, loginCount } });
};
export const login = async (token) => {
  const decodedToken = jwtDecode(token);
  // Duplicated here for render calls from zustand
  setAuthorizationHeader(token);
  const authState = useAuthentication.getState();
  useAuthentication.setState(
    {
      decodedToken,
      token,
      // Only advance the count if not already signed in
      loginCount: (authState.loginCount || 0) + (authState.decodedToken ? 0 : 1),
      ...(decodedToken?.isSudoLogin
        ? {
            sudo: authState,
          }
        : {}),
    },
    false,
    {
      type: 'refreshProfile',
    },
  );
  const profile = await _authenticate(token, decodedToken);
  broadcastState();
  return profile;
};
export const exitSudo = async () => {
  const authState = useAuthentication.getState();
  if (authState.decodedToken?.isSudoLogin) {
    if (!authState.sudo) {
      // This is a bug where sudo credentials disappear from the state, however in this case we will force th logout
      deauthenticate();
    } else {
      // Duplicated here for render calls from zustand
      setAuthorizationHeader(authState.sudo.token);
      useAuthentication.setState(
        {
          ...authState.sudo,
          sudo: null,
        },
        false,
        {
          type: 'exitSudo',
        },
      );
      const profile = await _authenticate(authState.sudo.token, authState.sudo.decodedToken);
      broadcastState();
      return profile;
    }
  }
};

let _deauthHandler;
// removing the token
export const registerDeauthHandler = (deauthHandler) => {
  _deauthHandler = deauthHandler;
};
const _deauthenticate = () => {
  setAuthorizationHeader(null);
  useAuthentication.setState({ profile: null, decodedToken: null, token: null, sudo: null }, false, {
    type: 'deauthenticate',
  });
  _deauthHandler();
  refreshGA4Config();
};
export const registerBroadcastAuthentication = () => {
  return registerBroadcastListener(async (data) => {
    if (data.authenticated !== null) {
      if (data.authenticated) {
        setAuthorizationHeader(data.state.token);
        useAuthentication.setState(data.state, false, {
          type: 'loadState',
        });
        await _authenticate(data.state.token, data.state.decodedToken);
      } else {
        _deauthenticate();
      }
    }
  });
};
// removing the token
export const deauthenticate = () => {
  return signout()
    .catch(() => {})
    .finally(() => {
      _deauthenticate();
      broadcastState();
    });
};

export const authenticate = async () => {
  const authState = useAuthentication.getState();
  if (isValidToken(authState.decodedToken)) {
    setAuthorizationHeader(authState.token);
    const profile = await _authenticate(authState.token, authState.decodedToken);

    return profile;
  } else if (authState.token) {
    await deauthenticate();
  } else if (authState.profile) {
    // Shouldnt happen but remove the profile info if there is a mismatch
    useAuthentication.setState({ profile: null, decodedToken: null, token: null, sudo: null }, false, {
      type: 'authenticate',
    });
  }
};

export const checkAuthTokenRefresh = async () => {
  const authState = useAuthentication.getState();
  const tokenExpireTime = authState?.decodedToken?.exp;
  const isSudoLogin = authState?.decodedToken?.isSudoLogin;
  const currentTime = Math.floor(new Date().getTime() / 1000) + 60;
  if (!isSudoLogin && tokenExpireTime > currentTime) {
    const diff = tokenExpireTime - currentTime;
    if (diff <= 3600) {
      const { token } = await refreshToken();
      return await login(token);
    } else {
      setTimeout(async () => {
        const { token } = await refreshToken();
        return await login(token);
      }, (diff - 3600) * 1000);
    }
  }
};

export const checkIsUserVerified = () => {
  getIsUserVerified().then((res) => {
    if (res?.isVerified && res?.token) {
      return login(res?.token);
    }
  });
};
