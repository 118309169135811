import { useRef } from 'react';

const isPromise = (value) => {
  return !!(value && value.then && typeof value.then === 'function' && value?.constructor?.name === 'Promise');
};
/*
 * This Debounce can be cancellable. For example, if a second call were to happen the first will not return.
 * In order to have this affect the promise architecture must be used.
 * Ex.
 * debounce(()=> <long api call here> )
 *    .then(()=> <result that may or may not complete if another call happens>)
 *    .catch(()=> <catch that may or may not complete if another call happens>)
 */
export class Debounce {
  timer = null;
  runAtEnd = false;
  runFirst = false;
  callerId = 0;
  nextEvent = null;
  constructor(timeout, options = {}) {
    const { runAtEnd, runFirst } = options;
    if (runAtEnd != null) this.runAtEnd = runAtEnd;
    if (runFirst != null) this.runFirst = runFirst;
    this.timeout = timeout;
  }
  debounce = (e) => {
    const localCallerId = ++this.callerId;
    return new Promise((resolve, reject) => {
      clearTimeout(this.timer);
      this.timer = null;
      if (this.runFirst) {
        this.runFirst = false;
        const ret = e();
        if (isPromise(ret) && localCallerId === this.callerId) ret.then(resolve).catch(reject);
      } else {
        this.timer = setTimeout(() => {
          clearTimeout(this.timer);
          this.timer = null;
          const ret = e();
          if (isPromise(ret) && localCallerId === this.callerId) ret.then(resolve).catch(reject);
        }, this.timeout);
      }
    });
  };
  predebounce = (e) => {
    const localCallerId = ++this.callerId;
    return new Promise((resolve, reject) => {
      this.nextEvent = { event: e, resolve, reject };
      if (this.timer == null) {
        const setTimer = () => {
          this.timer = setTimeout(() => {
            if (this.runAtEnd && this.nextEvent) {
              const ret = this.nextEvent.event();
              if (isPromise(ret)) ret.then(this.nextEvent.resolve).catch(this.nextEvent.reject);
            }
            clearTimeout(this.timer);
            this.timer = null;
          }, this.timeout);
        };
        const ret = this.nextEvent.event();
        if (isPromise(ret) && localCallerId === this.callerId) {
          ret.then(this.nextEvent.resolve).catch(this.nextEvent.reject).then(setTimer);
        } else {
          setTimer();
        }
        this.nextEvent = null;
        this.timer = true;
      }
    });
  };
}
export const useDebounce = (timeout, options) => {
  const debounce = useRef(new Debounce(timeout, options));

  return debounce.current;
};
