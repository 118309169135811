const constArray = [];
export const getFilterValue = (filter) => {
  switch (filter?.type) {
    case 'input':
      return filter?.selected;
    case 'singleSelect':
      return filter?.selected?.value;
    case 'multiSelect':
      return filter?.selectedVal?.length > 0 ? filter.selectedVal : constArray;
  }
};
export const setFilterValue = (filterStore, selected) => {
  const state = filterStore.getState();
  switch (state.type) {
    case 'input':
      filterStore.setState({ selected }, false, {
        type: 'setFilterValue',
      });
      break;
    case 'singleSelect':
      filterStore.setState({ selected }, false, {
        type: 'setFilterValue',
      });
      break;
    case 'multiSelect':
      if (selected instanceof Object && !Array.isArray(selected)) {
        filterStore.setState(
          {
            selected: [...(state.selected ?? []), selected],
            selectedVal: Array.from(
              new Set([state.selectedVal ?? [], selected.value].reduce((t, a) => [...t, ...(Array.isArray(a) ? a : [a])], [])),
            ).sort(),
          },
          false,
          {
            type: 'setFilterValue',
          },
        );
      } else {
        filterStore.setState(
          {
            selected,
            selectedVal: Array.from(
              new Set(selected.map(({ value }) => value).reduce((t, a) => [...t, ...(Array.isArray(a) ? a : [a])], [])),
            ).sort(),
          },
          false,
          {
            type: 'setFilterValue',
          },
        );
      }
      break;
  }
};
export const areFilterStatesEqual = (state1, state2) => {
  const compareStatesFunc = state1.compareStates ?? state2.compareStates;
  if (compareStatesFunc) return compareStatesFunc(state1, state2);
  else return compareFilterStatesEqual(state1, state2);
};
export const compareFilterStatesEqual = (state1, state2) => {
  const a = getFilterValue(state1);
  const b = getFilterValue(state2);
  if (Array.isArray(a) || Array.isArray(b)) {
    if (a?.length !== b?.length) return false;
    return (a ?? []).every((x) => (b ?? []).includes(x));
  } else if (!a && !b) {
    return true;
  } else {
    return a === b;
  }
};
