import { SVG } from './SVG';
const IconAvatar = ({ color = '#4062BB' }) => (
  <SVG width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.6703 24.3407C19.1157 24.3407 24.3407 19.1157 24.3407 12.6703C24.3407 6.22499 19.1157 1 12.6703 1C6.22499 1 1 6.22499 1 12.6703C1 19.1157 6.22499 24.3407 12.6703 24.3407Z"
      stroke={color}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.6704 13.6704C14.3273 13.6704 15.6704 12.3273 15.6704 10.6704C15.6704 9.01356 14.3273 7.67041 12.6704 7.67041C11.0136 7.67041 9.67041 9.01356 9.67041 10.6704C9.67041 12.3273 11.0136 13.6704 12.6704 13.6704Z"
      stroke={color}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.6704 18.6704C15.3524 16.0037 9.98838 16.0037 6.67041 18.6704"
      stroke={color}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVG>
);
export { IconAvatar as default, IconAvatar };
