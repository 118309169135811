import { cspReport } from '@medifind/interface';
import { useEventListener } from '@medifind/utils';

const reportedBlockedURI = [];

export const useCspReport = ({ disable = false }) => {
  if (disable) return;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEventListener(
    'securitypolicyviolation',
    (evt) => {
      if (reportedBlockedURI.includes(evt.blockedURI)) return;
      cspReport({
        'csp-report': {
          'blocked-uri': evt.blockedURI,
          disposition: evt.disposition,
          'document-uri': evt.documentURI,
          'effective-directive': evt.effectiveDirective,
          'original-policy': evt.originalPolicy,
          referrer: evt.referrer,
          'status-code': evt.statusCode,
          'violated-directive': evt.violatedDirective,
          timeStamp: evt.timeStamp,
        },
      });
      reportedBlockedURI.push(evt.blockedURI);
    },
    [],
  );
};
