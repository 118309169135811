import { v4 as uuidv4 } from 'uuid';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

const storeName = 'notificationCenter';
export const useNotificationCenter = create(
  devtools(
    () => ({
      queue: [],
    }),
    {
      name: `${process.env.NX_APP}`,
      store: storeName,
    },
  ),
);

export const addNotification = ({ event, ...props }) => {
  const id = uuidv4();
  const queue = useNotificationCenter.getState().queue;
  useNotificationCenter.setState(
    {
      queue: [{ id, ...props }, ...queue.filter((x) => !event || x.event !== event)],
    },
    false,
    {
      type: 'addNotification',
    },
  );
};

export const removeNotification = (id) => {
  const queue = useNotificationCenter.getState().queue;
  useNotificationCenter.setState(
    {
      queue: queue.filter((x) => x.id !== id),
    },
    false,
    {
      type: 'removeNotification',
    },
  );
};
