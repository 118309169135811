import { SVG } from './SVG';
const IconSearch = ({ color = '#4062BB', ...props }) => (
  <SVG width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" stroke={color} {...props}>
    <path
      d="M10.1803 16.5C13.6402 16.5 16.445 13.6858 16.445 10.2143C16.445 6.7428 13.6402 3.92859 10.1803 3.92859C6.72031 3.92859 3.91547 6.7428 3.91547 10.2143C3.91547 13.6858 6.72031 16.5 10.1803 16.5Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M18.0113 18.0714L14.6048 14.6536" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </SVG>
);

export { IconSearch as default, IconSearch };
