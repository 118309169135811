import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './Skeleton.module.scss';

const Skeleton = ({
  count = 1,
  style = {},
  className,
  parentClassName,
  parentStyle,
  width,
  height,
  showAnimation = true,
  showSkeleton = true,
  children,
}) => {
  return (
    showSkeleton && (
      <span className={parentClassName} style={parentStyle}>
        {[...Array(count).keys()].map((x, i) => (
          <span
            key={i}
            className={classNames(styles['skeleton'], className, { [styles['with-animation']]: showAnimation })}
            style={{ ...style, width, height }}
          >
            {children ? <span className={styles['children']}>{children}</span> : <>&zwnj;</>}
          </span>
        ))}
      </span>
    )
  );
};

Skeleton.propTypes = {
  count: PropTypes.number,
  style: PropTypes.object,
  parentStyle: PropTypes.object,
  className: PropTypes.string,
  parentClassName: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  showAnimation: PropTypes.bool,
  showSkeleton: PropTypes.bool,
  children: PropTypes.node,
};
export { Skeleton as default, Skeleton };
