import { useEffect, useState } from 'react';
import { useRouter } from '@medifind/router';

const RageClickCircles = () => {
  const router = useRouter();
  const { rage_click: rageClicks, screen_size: screenSize } = router.query;
  const [requestPermissions, setRequestPermissions] = useState(false);
  const [seePopup, setSeePopup] = useState(false);

  useEffect(() => {
    if (rageClicks && screenSize) {
      if (
        !window.open(
          window.location.href.replace(/screen_size=[0-9,]+/, ''),
          'mf_rage_popup',
          `resizable,width=${screenSize.split(',')[0]},height=${screenSize.split(',')[1]}`,
        )
      )
        setRequestPermissions(true);
      else setSeePopup(true);
    }
  }, []);
  if (!rageClicks) return;
  if (requestPermissions || seePopup) {
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: '#c4c4c4',
          zIndex: 9999,
          fontSize: '3rem',
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {seePopup ? 'See Popup' : 'Please Allow Popups and Refresh the Page'}
      </div>
    );
  }

  // Ex: ?rage_click=690,495,876,453,999,463
  const rageClicksArray = `${rageClicks}`.split(',');
  const coordinates = [];
  for (let i = 0; i < rageClicksArray.length; i += 2) {
    coordinates.push({
      x: rageClicksArray[i],
      y: rageClicksArray[i + 1],
    });
  }

  // const coordinates = [
  //   { x: 690, y: 495 },
  //   { x: 876, y: 453 },
  //   { x: 999, y: 463 },
  // ];

  const plotCircle = (val, i) => {
    return (
      <div
        key={i}
        style={{
          position: 'absolute',
          zIndex: '99999',
          width: '20px',
          height: '20px',
          borderRadius: '50%',
          backgroundColor: 'rgb(255,255,0,0.8)',
          left: val.x + 'px',
          top: val.y + 'px',
          userSelect: 'none',
          pointerEvents: 'none',
        }}
      ></div>
    );
  };

  return coordinates.map((val, i) => plotCircle(val, i));
};
export { RageClickCircles as default, RageClickCircles };
