import { useEffect, useRef } from 'react';

const focusableElementsSelector = `
  a[href]:not(:disabled):not([tabindex="-1"]),
  button:not(:disabled):not([tabindex="-1"]),
  input:not(:disabled):not([tabindex="-1"]),
  textarea:not(:disabled):not([tabindex="-1"]),
  select:not(:disabled):not([tabindex="-1"]),
  details:not(:disabled):not([tabindex="-1"]),
  [tabindex]:not(:disabled):not([tabindex="-1"])
`;

const selectNextFocusableElem = (trapRefElem, sortedFocusableElems, currentIndex, shiftKeyPressed = false, skipCount = 0) => {
  if (skipCount > sortedFocusableElems.length) {
    return false;
  }

  const backwards = !!shiftKeyPressed;
  const maxIndex = sortedFocusableElems.length - 1;

  if (!currentIndex) {
    currentIndex = sortedFocusableElems.indexOf(document.activeElement) ?? 0;
  }

  const nextIndex = backwards ? currentIndex - 1 : currentIndex + 1;
  if (nextIndex > maxIndex) {
    const save = trapRefElem.tabIndex;
    trapRefElem.tabIndex = 0;
    trapRefElem.focus();
    trapRefElem.tabIndex = save;
  } else if (nextIndex < 0) {
    const endElem = trapRefElem.nextElementSibling;
    if (endElem == null) {
      const newEndElem = trapRefElem.parentNode.appendChild(document.createElement('div', { tabIndex: 0 }));
      newEndElem.focus({ preventScroll: true });
      trapRefElem.parentNode.removeChild(newEndElem);
    } else {
      const save = endElem.tabIndex;
      endElem.tabIndex = 0;
      endElem.focus({ preventScroll: true });
      endElem.tabIndex = save;
    }
  }
};

export const useFocusTrap = () => {
  const trapRef = useRef(null);

  useEffect(() => {
    const trapper = (event) => {
      const trapRefElem = trapRef.current;

      if (trapRefElem !== null) {
        if (event.keyCode === 9 || event.key === 'Tab') {
          const shiftKeyPressed = !!event.shiftKey;
          const focusableElems = Array.from(trapRefElem.querySelectorAll(focusableElementsSelector))
            .filter((focusableElement) => focusableElement.getAttribute('tabindex') >= 0)
            .filter((elem) => {
              const style = window.getComputedStyle(elem);
              return style.visibility !== 'hidden' && style.display !== 'none';
            });

          selectNextFocusableElem(trapRefElem, focusableElems, undefined, shiftKeyPressed);
        }
      }
    };
    window.addEventListener('keydown', trapper);

    return () => {
      window.removeEventListener('keydown', trapper);
    };
  }, []);

  return [trapRef];
};
