import { useLayoutEffect, useState } from 'react';
import { SEO } from '@medifind/seo';
import { isPrerender } from '@medifind/utils';
import { listen } from './listen';

export const CheckPrerenderRedirect = () => {
  const [redirect, setRedirect] = useState();
  useLayoutEffect(() => {
    const routeChange = (url, type, previousPage) => {
      if (isPrerender && !previousPage.includes(url)) {
        url = url.replace(window.location.origin, '');

        if (type === 'PUSH') {
          setRedirect({ location: url, code: 302 });
        } else if (type === 'REPLACE') {
          setRedirect({ location: url, code: 301 });
        }
      }
    };
    const unListen = listen(routeChange);
    return unListen;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (redirect && isPrerender) {
    const url = redirect?.location?.replace(/[?&]prerender=true/, '');
    return <SEO statusCode={redirect?.code} redirectLocation={url} />;
  } else return null;
};
