import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { migrateReduxSessionStorageValue, sessionStorageMF } from '../utils';

const storeName = 'featureFlags';
const initialState = {
  flags: process.env.NX_FEATURE_FLAGS
    ? JSON.parse(process.env.NX_FEATURE_FLAGS).reduce((acc, flag) => {
        acc[flag] = true;
        return acc;
      }, {})
    : {},
};
export const useFeatureFlagStore = create(
  devtools(
    persist(
      (set) => ({
        ...initialState,
        set,
        reset: () => set({ ...initialState }, false, { type: 'reset' }),
      }),
      {
        name: `${process.env.NX_APP}-${storeName}`,
        version: 0,
        storage: createJSONStorage(() => sessionStorageMF),
        onRehydrateStorage: () => (state) => {
          migrateReduxSessionStorageValue({ state, key: 'featureFlags' });
        },
      },
    ),
    {
      name: process.env.NX_APP,
      store: storeName,
    },
  ),
);

export const setFeatureFlag = (name, on) => {
  useFeatureFlagStore.setState({ flags: { ...useFeatureFlagStore.getState()?.flags, [name]: on } }, false, {
    type: 'setFeatureFlag',
  });
};
export const getFeatureFlag = (name) => {
  return useFeatureFlagStore.getState()?.flags?.[name];
};
