import { useEffect, useState } from 'react';
import { addPassiveListener } from '@medifind/shared-basic-components';

const breakpoints = [
  { name: 'none', value: 0 },
  { name: 'xs', value: 576 },
  { name: 'sm', value: 768 },
  { name: 'md', value: 992 },
  { name: 'lg', value: 1200 },
  { name: 'xl', value: 1400 },
  { name: 'xxl' },
];
const breakpointLookup = breakpoints.reduce((t, a, i) => {
  t[a.name] = i;
  return t;
}, {});
export const getViewportBreakpoint = () => {
  return breakpoints.find((b) => !b.value || window.innerWidth < b.value);
};
const getBreakpointWidth = (breakpoint, type = 'down') => {
  if (type == 'down') return (breakpoints[breakpointLookup[breakpoint]]?.value ?? 99999999) - 0.01;
  else {
    return breakpoints[breakpointLookup[breakpoint] - 1]?.value ?? 0;
  }
};
export const isBreakpointDown = (breakpoint) => window.innerWidth < getBreakpointWidth(breakpoint);
export const getValueMediaBreakpointDown = (options) => {
  let minValue = {};
  Object.keys(options)
    .map((breakpoint) => {
      return { breakpoint, breakpointValue: isNaN(breakpoint) ? getBreakpointWidth(breakpoint) : parseFloat(breakpoint) };
    })
    .sort((a, b) => a.breakpointValue - b.breakpointValue)
    .map(({ breakpoint, breakpointValue }) => {
      if (window.innerWidth <= breakpointValue && (minValue.breakpointValue == null || minValue.breakpointValue > breakpointValue)) {
        minValue = { breakpointValue, value: options[breakpoint] };
      }
    });
  return minValue.value;
};
const useMediaQuery = (queries) => {
  const checkQueries = () => {
    return queries.some(({ minWidth, maxWidth, minHeight, maxHeight }) => {
      return (
        (!minWidth || window.innerWidth >= minWidth) &&
        (!maxWidth || window.innerWidth <= maxWidth) &&
        (!minHeight || window.innerHeight >= minHeight) &&
        (!maxHeight || window.innerHeight <= maxHeight)
      );
    });
  };
  const [isValid, setIsValid] = useState(checkQueries);
  useEffect(
    () =>
      addPassiveListener(
        'resize',
        () => {
          if (isValid !== checkQueries()) setIsValid(!isValid);
        },
        0,
      ),
    [isValid],
  );

  return isValid;
};
// eslint-disable-next-line valid-jsdoc
/**
 * ex.
 * useMediaQueryDown('md') -> (max-width: 991.98px)
 * useMediaQueryUp('md')   -> (min-width: 768px)
 * useMediaQueryOnly('md') -> (min-width: 768px) and (max-width: 991.98px)
 */
export const useMediaQueryDown = (breakpoint) => {
  return useMediaQuery([{ maxWidth: getBreakpointWidth(breakpoint, 'down') }]);
};
export const useMediaQueryUp = (breakpoint) => {
  return useMediaQuery([{ minWidth: getBreakpointWidth(breakpoint, 'up') }]);
};
export const useMediaQueryOnly = (breakpoint) => {
  return useMediaQuery([{ maxWidth: getBreakpointWidth(breakpoint, 'down'), minWidth: getBreakpointWidth(breakpoint, 'up') }]);
};

export const useMediaQueries = (breakpoints) => {
  return useMediaQuery(
    breakpoints.map((breakpoint) => ({ maxWidth: getBreakpointWidth(breakpoint, 'down'), minWidth: getBreakpointWidth(breakpoint, 'up') })),
  );
};
const breakpointNames = breakpoints.map((d) => d.name);
export const getSmallClassList = (smallAt = [], styles) => {
  const reverse = new Set(breakpointNames);
  smallAt.forEach((d) => {
    reverse.delete(d);
  });
  return {
    smallClassList: smallAt.map((s) => styles['small-at--' + s]),
    smallClassListReverse: Array.from(reverse).map((s) => styles['small-at--reverse--' + s]),
  };
};

export const getSmallAtList = (smallAt) => {
  const i = breakpointNames.indexOf(smallAt);
  if (i === -1) return [];
  return breakpointNames.slice(0, i);
};
