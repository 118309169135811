import { Suspense, lazy, useCallback, useState } from 'react';
import { useLazyComponentPreload } from '@medifind/hooks';
const MFModal = lazy(() => import('./MFModal'));

const useMFModal = (ref) => {
  useLazyComponentPreload(MFModal, 20000);
  const [isOpen, setIsOpen] = useState(false);
  const openModal = useCallback(() => {
    setIsOpen(true);
  }, []);
  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  const MFModalWrapper = useCallback(
    ({ onClose, ...props }) => {
      const handleClose = () => {
        setIsOpen(false);
        onClose && onClose(false);
      };
      return (
        isOpen && (
          <Suspense fallback={<div className="fallback" />}>
            <MFModal {...props} isOpen={isOpen} onClose={handleClose} />
          </Suspense>
        )
      );
    },
    [isOpen],
  );
  const response = {
    MFModal: MFModalWrapper,
    isModalOpen: isOpen,
    openModal,
    closeModal,
  };
  if (ref) ref.current = response;
  return response;
};

export { useMFModal as default, useMFModal };
