import { useEffect } from 'react';

const EventListener = ({ eventName, onEvent }) => {
  useEventListener(eventName, onEvent, []);

  return null;
};

const useEventListener = (eventName, onEvent, deps) => {
  useEffect(() => {
    document.addEventListener(eventName, onEvent, false);

    return () => {
      document.removeEventListener(eventName, onEvent, false);
    };
  }, deps);

  return null;
};

const eventDispatch = (eventName, data = {}) => {
  const event = document.createEvent('HTMLEvents');
  event.initEvent(eventName, true, true);
  Object.keys(data).forEach((k) => {
    event[k] = data[k];
  });
  document.dispatchEvent(event);
};

export { EventListener as default, EventListener, useEventListener, eventDispatch };
