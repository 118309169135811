import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { setHeaderVisibility } from '../header';
import { migrateReduxSessionStorageValue, sessionStorageMF } from '../utils';

const initialState = {
  history: [],
};
const storeName = 'history';
export const useHistoryStore = create(
  devtools(
    persist(
      (set) => ({
        ...initialState,
        set,
        reset: () => set({ ...initialState }, false, { type: 'reset' }),
      }),
      {
        name: `${process.env.NX_APP}-${storeName}`,
        version: 0,
        storage: createJSONStorage(() => sessionStorageMF),
        onRehydrateStorage: () => (state) => {
          migrateReduxSessionStorageValue({ state, key: 'history' });
        },
      },
    ),
    {
      name: `${process.env.NX_APP}`,
      store: storeName,
    },
  ),
);

export const selectHistory = (state) => state.history;

export const historyPop = (url) => {
  const history = useHistoryStore.getState().history;
  if (history.length > 1) {
    useHistoryStore.setState({ history: history.slice(0, -1) }, false, {
      type: 'historyPop',
    });
  }
  setHeaderVisibility({ visible: false });
};

export const historyPush = (url) => {
  const history = useHistoryStore.getState().history;
  if (history.length > 1 && history[history.length - 2] === url) {
    useHistoryStore.setState({ history: history.slice(0, -1) }, false, {
      type: 'historyPush',
    });
  } else if (history[history.length - 1] === url) {
    return;
  } else {
    useHistoryStore.setState({ history: [...history, url] });
  }
  setHeaderVisibility({ visible: false });
};

export const historyReplace = (url) => {
  const history = useHistoryStore.getState().history;
  useHistoryStore.setState({ history: [...history.slice(0, -1), url] }, false, {
    type: 'historyReplace',
  });
};

export const historyInit = (url) => {
  const history = useHistoryStore.getState().history;
  const referer = document?.referrer?.includes(process.env.NX_LOCAL_HOST) && document.referrer.match(/https?:\/\/[^/]+(.*)/)[1];
  if (history.length === 0 && referer !== url) {
    useHistoryStore.setState(
      {
        history: [url].filter(Boolean),
        // Referer was removed I forget why this was good, I think if you were refereed by our site there was a history?
      },
      false,
      {
        type: 'historyInit',
      },
    );
  } else {
    if (history.length > 0 && history[history.length - 1] === url) {
      return;
    } else {
      return useHistoryStore.setState({
        history: [...history, url],
      });
    }
  }
};
