import { SVG } from './SVG';
const IconLogo = ({ darkMode, ...props }) => (
  <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 474.5 100.9" {...props}>
    <path
      fill={darkMode ? '#fff' : '#112053'}
      stroke={darkMode ? '#F8F8F8' : '#112053'}
      d="M13.1 70.9a40.6 40.6 0 0 1 0-58 41.9 41.9 0 0 1 58.6.1 40.6 40.6 0 0 1 0 57.9L44.2 98a2.5 2.5 0 0 1-3.5 0L13 70.9Z"
    />
    <path fill={darkMode ? '#112053' : '#ebebeb'} d="M33.9 41h-3V20c0-1.6 1.3-2.8 2.9-2.8h17.6a2.8 2.8 0 0 1 2.8 2.8v7h-3v-7H33.9v21Z" />
    <path fill={darkMode ? '#112053' : '#ebebeb'} d="M32.2 44a3.6 3.6 0 1 0 0-7.2 3.6 3.6 0 0 0 0 7.2Z" />
    <path fill="#91A6FF" d="M51.4 69.5H33.8a2.8 2.8 0 0 1-2.9-2.8v-7.1h3v7.1h17.5l-.1-21h2.9v21c0 1.6-1.3 2.8-2.8 2.8Z" />
    <path fill="#91A6FF" d="M52.7 49.3a3.6 3.6 0 1 0 0-7.2 3.6 3.6 0 0 0 0 7.2Z" />
    <path fill="#4062bb" d="M66 55h-7.2v-3H66l-.2-17.5-21 .2v-3H66a2.8 2.8 0 0 1 2.8 2.8v17.6c0 1.6-1.3 2.9-2.8 2.9Z" />
    <path fill="#4062bb" d="M44.9 36.8a3.6 3.6 0 1 0 0-7.2 3.6 3.6 0 0 0 0 7.2Z" />
    <path fill="#E5243E" d="M40.3 54.4H19.2a2.8 2.8 0 0 1-2.8-2.8V34c0-1.6 1.2-2.9 2.8-2.9h7.1v3h-7.1v17.5l21-.1v3Z" />
    <path fill="#E5243E" d="M40.3 57.1a3.6 3.6 0 1 0 0-7.2 3.6 3.6 0 0 0 0 7.2Z" />
    <path
      fill={darkMode ? '#FEFEFE' : '#4062bb'}
      d="M286.7 36.2a6.4 6.4 0 0 0 4.8-1.8 6.4 6.4 0 0 0 1.4-2.1 6.5 6.5 0 0 0 .5-2.6 6.7 6.7 0 0 0-2-4.6 6.7 6.7 0 0 0-4.7-1.9 6.7 6.7 0 0 0-6.7 6.5 6.4 6.4 0 0 0 6.7 6.5m11.6 40.6-4.4-3.3a2 2 0 0 1-.8-1.6V42.4a2 2 0 0 0-2-2h-15.7a.5.5 0 0 0-.5.5v.8a.5.5 0 0 0 .2.4l4.5 3.2a2 2 0 0 1 .8 1.7v24.9a2 2 0 0 1-.8 1.6l-4.5 3.3a.5.5 0 0 0-.2.4v.8a.5.5 0 0 0 .5.4H298a.5.5 0 0 0 .5-.4v-.8a.5.5 0 0 0-.2-.4zm-43.8-17.2c0 8.4-4 14.4-9.1 14.4-5.5 0-9-6.4-9-14.5s3.5-14.6 9-14.6c5.2 0 9.1 5.9 9.1 14.3v.4zm18 17.2-4.7-3.4a1.5 1.5 0 0 1-.6-1.3V21.8a1.5 1.5 0 0 0-.5-1 1.5 1.5 0 0 0-.5-.4 1.7 1.7 0 0 0-.6-.1h-16a.5.5 0 0 0-.5.3.5.5 0 0 0 0 .2v.5a.5.5 0 0 0 .2.4l4.6 3.6a1.5 1.5 0 0 1 .6 1.2v24.7a14.7 14.7 0 0 0-14.8-11.7c-9.9 0-16.9 7.6-16.9 20s7 20 17 20a14.7 14.7 0 0 0 9.4-3.2 14.8 14.8 0 0 0 5.3-8.6V77a1.5 1.5 0 0 0 1.5 1.5h16.1a.5.5 0 0 0 .4-.1.5.5 0 0 0 .1-.4v-.8a.6.6 0 0 0 0-.2.5.5 0 0 0-.1-.1zm-73-36c4.5 0 6.7 3.5 7.1 14.3a.5.5 0 0 1-.4.5h-14a.5.5 0 0 1-.4-.5c.4-9.8 2.9-14.4 7.7-14.4zm-7.2 18.6h25a1.5 1.5 0 0 0 1.1-.5 1.5 1.5 0 0 0 .4-1.1c-.6-11.4-8-18.3-19.3-18.3-11.8 0-21.3 8-21.3 20 0 11.8 9.6 20 21.5 20 8.9 0 15.4-4.8 18.5-13.4a.3.3 0 0 0-.1-.3.3.3 0 0 0-.1 0l-.6-.3a.3.3 0 0 0-.4.1c-3 5.2-7 7.2-11.8 7.2-7.8 0-12.7-6.2-13.4-12.9a.5.5 0 0 1 .3-.5.4.4 0 0 1 .2 0zm-19.2-34.3 4.3-3.1a.5.5 0 0 0 .2-.4v-.8a.5.5 0 0 0-.5-.5H162a.5.5 0 0 0-.4.3l-1.9 3.4L148 45.8a.5.5 0 0 1-.9 0l-14.7-25.3a.5.5 0 0 0-.4-.2h-17a.5.5 0 0 0-.5.5v.8a.5.5 0 0 0 .2.4l3.3 2.7a2 2 0 0 1 .7 1.6v46.5a2 2 0 0 1-.8 1.6l-3.2 2.4a.5.5 0 0 0-.2.4v.8a.5.5 0 0 0 .5.4h11.4a.5.5 0 0 0 .5-.4v-.8a.5.5 0 0 0-.2-.4l-3.4-2.5a2 2 0 0 1-.8-1.6V32a.5.5 0 0 1 1-.2l16.2 28.4a.5.5 0 0 0 .5.2h4a.5.5 0 0 0 .3-.2l15-27.6V72a2 2 0 0 1-.9 1.6l-4.3 3.2a.5.5 0 0 0-.2.4v.7a.5.5 0 0 0 .4.5h22.7a.5.5 0 0 0 .5-.4v-.8a.5.5 0 0 0-.2-.4l-4.3-3.2a2 2 0 0 1-.6-.7 2 2 0 0 1-.3-1V26.8a2 2 0 0 1 .3-.9 2 2 0 0 1 .6-.7z"
    />
    <path
      fill={darkMode ? '#FEFEFE' : '#242a45'}
      d="m369.5 76.8-4.5-3.3a2 2 0 0 1-.8-1.6V42.4a2 2 0 0 0-2-2h-15.6a.5.5 0 0 0-.5.5v.8a.5.5 0 0 0 .2.4l4.5 3.2a2 2 0 0 1 .8 1.7v24.9a2 2 0 0 1-.8 1.6l-4.4 3.3a.5.5 0 0 0-.2.4v.8a.5.5 0 0 0 .4.4h22.6a.5.5 0 0 0 .5-.4v-.8a.5.5 0 0 0-.2-.4zM343 20.3h-41.3a.5.5 0 0 0-.4.5v.8a.5.5 0 0 0 .2.3l4.4 3.2a2 2 0 0 1 .8 1.7v45.1a2 2 0 0 1-.2 1 2 2 0 0 1-.6.6l-4.4 3.3a.5.5 0 0 0-.2.2.4.4 0 0 0 0 .2v.8a.5.5 0 0 0 .5.4h26.1a.5.5 0 0 0 .5-.4v-.8a.5.5 0 0 0-.3-.4l-7.2-3.3a2 2 0 0 1-1.2-1.8V53.4a2 2 0 0 1 .6-1.4 2 2 0 0 1 1.4-.6h8.3a2 2 0 0 1 1.6.7l5 6.3a.5.5 0 0 0 .5.2h.9a.5.5 0 0 0 .4-.3.5.5 0 0 0 0-.2V40.9a.5.5 0 0 0-.2-.4.5.5 0 0 0-.2 0h-1a.5.5 0 0 0-.3.1l-5 6.2a2 2 0 0 1-1.6.8h-8.4a2 2 0 0 1-2-2V26a2 2 0 0 1 .6-1.4 2 2 0 0 1 1.4-.6h12.8a2 2 0 0 1 1.7 1l7 11.9a.5.5 0 0 0 .5.2h.8a.5.5 0 0 0 .5-.5V22.3c0-.3 0-.5-.2-.8a2.1 2.1 0 0 0-.4-.6 2 2 0 0 0-1.4-.6z"
    />
    <path
      fill={darkMode ? '#FEFEFE' : '#4062bb'}
      d="M362.2 22.3a7.2 7.2 0 0 0-10 0 7.1 7.1 0 0 0 0 10.1l4.1 4.2a1.1 1.1 0 0 0 .8.3 1.1 1.1 0 0 0 .9-.3l4.2-4.2a7.1 7.1 0 0 0 0-10.1z"
    />
    <path
      fill={darkMode ? '#FEFEFE' : '#242a45'}
      d="M453.4 59.6c0 8.4-4 14.4-9.1 14.4-5.5 0-9-6.4-9-14.5s3.5-14.6 9-14.6c5.2 0 9.1 5.9 9.1 14.3v.4zm18 17.2-4.6-3.3a2 2 0 0 1-.8-1.6V22.3a2 2 0 0 0-2-2h-15.6a.5.5 0 0 0-.5.5v.5a.5.5 0 0 0 .2.4l4.5 3.4a2 2 0 0 1 .8 1.6v21.4a.5.5 0 0 1-.1.3.5.5 0 0 1-.9 0 14.6 14.6 0 0 0-13.9-9c-9.9 0-16.9 7.7-16.9 20s7 20 17 20a14.7 14.7 0 0 0 8.3-2.3 14.6 14.6 0 0 0 5.5-6.6.5.5 0 0 1 1 .2v5.7a2 2 0 0 0 .6 1.4c.1.2.4.3.6.4l.8.2H471a.5.5 0 0 0 .5-.5v-.8a.5.5 0 0 0 0-.2l-.2-.1zm-50.6 0-4.5-3.3a2 2 0 0 1-.8-1.6V53.3c0-9.5-4.8-13.9-12-13.9-6.7 0-10.3 3.5-12.3 8.6a.5.5 0 0 1-.5.4.5.5 0 0 1-.4-.5v-5.5a2 2 0 0 0-2-2h-15.7a.5.5 0 0 0-.4.5v.8a.5.5 0 0 0 .2.4l4.5 3.2a2 2 0 0 1 .8 1.7v24.9a2 2 0 0 1-.8 1.6l-4.6 3.3a.5.5 0 0 0-.2.4v.7a.5.5 0 0 0 .3.5.5.5 0 0 0 .2 0h21.5a.5.5 0 0 0 .4-.5v-.8a.5.5 0 0 0 0-.2.5.5 0 0 0-.1-.1l-3.5-3.3a2 2 0 0 1-.7-1.5V55.7c0-5 2.5-9.7 7.1-9.7 3.7 0 5.6 2.6 5.6 7.7V72a2 2 0 0 1-.7 1.5l-3.5 3.3a.5.5 0 0 0-.2.3v.9a.5.5 0 0 0 .5.4h21.6a.5.5 0 0 0 .4-.4v-.8a.5.5 0 0 0-.2-.4z"
    />
  </SVG>
);
export { IconLogo as default, IconLogo };
