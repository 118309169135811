import { clientFetch, hasAuthorizationToken } from '../clientFetch';

export const verifyEmail = (params) => {
  return clientFetch(`/user/verify-email`, { params });
};

export const resendVerificationCode = (email) => {
  return clientFetch(`/user/resend-verification`, { params: { email } });
};

export const postLogin = (body) => {
  return clientFetch(`/user/sign-in`, { method: 'POST', body });
};

export const facebookLoginCallback = (params) => {
  return clientFetch(`/user/facebook/sign-in/callback`, { params });
};

export const googleLoginCallback = (params) => {
  return clientFetch(`/user/google/sign-in/callback`, { params });
};

export const linkedInLoginCallback = (params) => {
  return clientFetch(`/user/linkedin/sign-in/callback`, { params });
};

export const changePassword = (body) => {
  return clientFetch('/user/change-password', { method: 'POST', body });
};

export const resetPassword = (body) => {
  return clientFetch('/user/reset-password', { method: 'POST', body });
};

export const forgotPassword = (body) => {
  return clientFetch('/user/forgot-password', { method: 'POST', body });
};

export const forgotPasswordTokenBased = ({ email }) => {
  return clientFetch('/user/forgot-password', { method: 'POST', body: { email } });
};
export const resetPasswordTokenBased = ({ token, password }) => {
  return clientFetch('/user/reset-password', { method: 'POST', body: { token, password } });
};

export const register = (body) => {
  return clientFetch(`/user/sign-up`, { method: 'POST', body });
};

export const getAuthMethod = ({ email, domain }) => {
  return clientFetch(`/user/getAuthMethod`, { params: { email, domain } });
};

export const adminSignIn = ({ token }) => {
  window.location.href = `${process.env.NX_API_HOST}/user/admin/sign-in?token=${token}`;
};

export const getAccounts = () => {
  return clientFetch(`/account/getAccounts`);
};

export const authorizeAccount = (account) => {
  return clientFetch(`/account/authorizeAccount`, { method: 'POST', body: { account } });
};

export const loginWithOauth = ({ code, state, scope }) => {
  return clientFetch(`/oauth2`, { params: { code, state, scope } });
};

export const signout = () => {
  return (hasAuthorizationToken ? clientFetch('/user/sign-out') : Promise.resolve({})).catch(() => {});
};

export const getUserList = (params) => {
  return clientFetch(`/search/listUsers/`, { params });
};

export const refreshToken = () => {
  return clientFetch(`/user/refresh-auth-token/`);
};

export const getIsUserVerified = () => {
  return clientFetch(`/user/is-verified`);
};

export default {
  resendVerificationCode,
  postLogin,
  facebookLoginCallback,
  googleLoginCallback,
  changePassword,
  resetPassword,
  forgotPassword,
  register,
  signout,
  getAuthMethod,
  adminSignIn,
  getAccounts,
  authorizeAccount,
  loginWithOauth,
  getUserList,
  refreshToken,
  linkedInLoginCallback,
  getIsUserVerified,
};
