import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { conditionAutocomplete } from '@medifind/interface';
import { clearConditionFilterOption, updateConditionFilterOption } from '../filters/condition';

const storeName = 'account';
export const useAccount = create(
  devtools(
    (set) => ({
      accounts: {},
      account: null,
    }),
    {
      name: `${process.env.NX_APP}`,
      store: storeName,
    },
  ),
);

export const setAccount = (decodedToken) => {
  const { isComsorteer, account } = decodedToken;
  if (account !== '*') {
    useAccount.setState({ account, isComsorteer: isComsorteer }, false, {
      type: 'setAccount',
    });
  }

  // Check for the global account condition
  if (account && account !== '*') {
    const { account: selectedAccount, accounts: accountStore } = useAccount.getState().account;
    if (selectedAccount) {
      const { condition } = accountStore[selectedAccount];
      if (condition) {
        if (condition.value) {
          // If it has been updated, ensure the therapeutic area filter is in sync
          updateConditionFilterOption(condition);
        } else {
          // If it has been cleared, ensure the condition filter is in sync
          clearConditionFilterOption();
        }
      } else {
        // If it was null pick the alphabetically first therapeutic area in the account
        conditionAutocomplete({ size: 1, showAll: true, useCancelToken: false })
          .then(({ results }) => {
            const firstCondition = results?.[0];
            if (firstCondition) {
              updateConditionFilterOption({
                display: firstCondition.display,
                value: firstCondition.projectId,
                accessRequest: {
                  accessDigitalPortal: firstCondition.accessDigitalPortal,
                  accessKOLPortal: firstCondition.accessKOLPortal,
                  accessNPOPortal: firstCondition.accessNPOPortal,
                  accessOrgPortal: firstCondition.accessOrgPortal,
                  accessPayorPortal: firstCondition.accessPayorPortal,
                },
              });
            }
          })
          .catch(() => null);
      }
    }
  }
};

export const clearAccountStore = () => {
  useAccount.setState({ account: null }, false, {
    type: 'clearAccountStore',
  });
};
