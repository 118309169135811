export const toSlug = (str, id) => {
  if (!str) return str;
  const nameSlug = str
    .toLowerCase()
    .replace(/[/\s]+/g, '-')
    .replace(/[*+~.,()'"!:@]/g, '');
  if (!id) return nameSlug;
  return `${nameSlug}_${id}`;
};

export const parseSlug = (str) => {
  if (!str) return [];
  const parts = str.split('_');
  if (parts.length === 1) return [str];
  const id = parseInt(parts.splice(parts.length - 1, 1)[0]);
  return [parts.join('_'), id];
};
