import queryStringParse from 'querystring/decode';

function convertValue(value, isSubObject = false) {
  if (value === null) return '';
  if (value instanceof Date) return value.toISOString();
  if (Array.isArray(value)) return `[${value.map((v) => convertValue(v, true)).join(',')}]`;
  if (typeof value === 'object') return encodeURIComponent(JSON.stringify(value));
  if (isSubObject) return encodeURIComponent(JSON.stringify(value));
  return encodeURIComponent(value);
}

export const getQueryStringFromMapAxios = (map, options) => {
  const { allowEmptyStrings = false } = options ?? {};
  if (!map) return null;
  return Object.keys(map)
    .sort()
    .filter((k) => map[k] != null && (allowEmptyStrings || map[k] !== ''))
    .map((k) => {
      if (Array.isArray(map[k])) {
        return map[k]
          .map((v) => {
            return `${k}[]=${convertValue(v)}`;
          })
          .join('&');
      } else {
        const value = convertValue(map[k]);
        return `${k}=${value}`;
      }
    })
    .filter(Boolean)
    .join('&');
};

export const getQueryStringFromMap = (map) => {
  if (!map) return null;
  return Object.keys(map)
    .sort()
    .filter((k) => map[k] != null && map[k] !== '')
    .map((k) => `${k}=${encodeURIComponent(map[k])}`)
    .join('&');
};

export const parseURL = (rawURL) => {
  const parsedUrl = rawURL.indexOf('http') === 0 ? new URL(rawURL) : new URL(rawURL, 'http://placeholder.com');
  parsedUrl.parsedParams = queryStringParse(parsedUrl.search.replace('?', ''));
  return parsedUrl;
};
export const generateUTMParams = (params = {}) => {
  return Object.keys(params || {})
    .map((k) => `utm_${k}=${params[k]}`)
    .join('&');
};
export const removeDuplicateParams = (url) => {
  const [path, params] = url.split('?');
  return path + '?' + new URLSearchParams(Object.fromEntries(new URLSearchParams(params))).toString();
};

export const generateURL = (path, queryParams, format = 'basic') => {
  const queryString =
    format === 'basic' ? getQueryStringFromMap(queryParams) : getQueryStringFromMapAxios(queryParams, { allowEmptyStrings: true });
  if (queryParams && Object.keys(queryParams).length > 0 && queryString) return `${path}?${queryString}`;
  return path;
};

export const generateModifiedNextURL = (router, queryParams, { clearExistingParams = false } = {}) => {
  const prevURL = parseURL(router.location.href);
  const prevParams = { ...prevURL.parsedParams };
  return generateURL(prevURL.pathname, { ...(clearExistingParams ? {} : prevParams), ...queryParams });
};

export const changeURLParams = (router, newQueryParams, { type = 'push', scroll = true, clearExistingParams = false } = {}) => {
  if (!clearExistingParams && Object.keys(newQueryParams).length === 0) return;
  const href = generateModifiedNextURL(router, newQueryParams, { clearExistingParams });

  router.history[type](href, { scroll });
};
