import { useCallback, useEffect, useRef } from 'react';
import { logEventGA4 } from '@medifind/zustand';
const possibleClick = 10;
// Time interval - var timeSpam value is in seconds, for example 10 is for ten seconds
const timeSpam = 10;
// Number of rage clicks
const noOfClicks = 3;
// Time interval - var time value is in seconds, for example 3 is for three seconds
const time = 3;
// Time interval - var time value is in seconds, for example 3 is for three seconds
const timeToKeepTrackingRageClicks = 3;
// internal variables, referring to the number of possible clicks within a set radius of pixels
const radius = 200; // Radius should only be set to 400 to prevent spam. Keep at 200 until prevent spam.
const RageClicks = () => {
  const clickEvents = useRef([]);

  const rageClicksQueue = useRef([]);
  const spamClicks = useRef([]);
  const autoTriggerGA = useRef();
  const preventSpam = useRef();

  // Check the distance to the last X clicks and see if the new click is within the radius
  const getDistance = (clickEvents, currentClick) => {
    clickEvents = clickEvents.concat([currentClick]);
    const last = clickEvents.length - 1;

    // check click distance. It has the max distance between the rage clicks.
    let maxDistance = 0;
    for (let i = last - noOfClicks + 1; i < last; i++) {
      for (let j = i + 1; j <= last; j++) {
        if (!clickEvents[i]) return null;
        const distance = Math.round(
          Math.sqrt(Math.pow(clickEvents[i].pageX - clickEvents[j].pageX, 2) + Math.pow(clickEvents[i].pageY - clickEvents[j].pageY, 2)),
        );
        if (distance > maxDistance) maxDistance = distance;
        if (distance > radius) return null;
      }
    }

    return maxDistance;
  };

  // Don't break the range click if next one clicked within 200px radius
  const detectRageClicks = (interval) => {
    const last = clickEvents.current.length - 1;

    if (clickEvents.current.length < noOfClicks) return false;

    // If a rage click event is happening see if we should add another click to it
    if (rageClicksQueue.current.length > 0) {
      const distance = getDistance(rageClicksQueue.current, clickEvents.current[last]);
      if (distance === null) return false;
      if (distance < radius) {
        rageClicksQueue.current = [...rageClicksQueue.current, clickEvents.current[last]];
      }

      // Otherwise detect a new rage click event
    } else {
      const timeDiff = (clickEvents.current[last].time.getTime() - clickEvents.current[last - noOfClicks + 1].time.getTime()) / 1000;
      // returns false if event period is longer than time(ex 3 secs)
      if (timeDiff > interval) return null;

      const distance = getDistance(clickEvents.current, clickEvents.current[last]);
      if (distance === null) return false;
      // If we detect a rage click add the clicks to the queue
      if (distance < radius) {
        rageClicksQueue.current = clickEvents.current.slice(-3);
      }
    }

    return true;
  };
  const onClick = useCallback((event) => {
    // Should not prevent the default from happening in prod
    /* if(rageClicksQueue.current.length > 0) {
          event.preventDefault() // cancels the default action that belongs to the event
          event.stopImmediatePropagation(); // execute the first event handler, and stop the rest of the event handlers from being executed
        }*/

    // Use pageX and pageY so that the offset with the scroll is retrieved
    const currentClick = {
      pageX: event.pageX,
      pageY: event.pageY,
      time: new Date(),
      id: new Date().getTime(),
      // Send the target id to GA if a button or tag was clicked durring a rage event. Send the last id of button or tag
      clickId:
        event.target.tagName === 'BUTTON' ||
        event.target.tagName === 'A' ||
        Array.from(event?.target?.classList)?.some((a) => a.includes('Button'))
          ? event.target.id || event.target.classList?.value
          : null,
    };

    // If range click in place and increase spam time to 10s
    // Radius for spam prevention is 400
    if (spamClicks.current.length > 0) {
      if (getDistance(spamClicks.current, currentClick) < 400 && new Date().getTime() < preventSpam.current) {
        preventSpam.current = new Date().getTime() + timeSpam * 1000;
        return;
      }
      spamClicks.current = [];
    }

    // Dont track clicks if preventing spam
    clickEvents.current.push(currentClick);

    // detect 3 click in 5 sec
    const result = detectRageClicks(time);

    if (result != null) {
      // Stop tracking clicks after 3s of them stopping and send to GA
      if (autoTriggerGA.current) clearTimeout(autoTriggerGA.current);
      autoTriggerGA.current = setTimeout(() => {
        if (rageClicksQueue.current.length > 0) {
          // Only get the click id from the rage click event
          const lastTargetId = rageClicksQueue.current
            .slice(0)
            .reverse()
            .find((r) => r.clickId);

          const clickList = rageClicksQueue?.current
            ?.splice(0, possibleClick)
            ?.map((x) => x.pageX + ',' + x.pageY)
            ?.toString(',');
          // Send to GA
          logEventGA4('rageClicks', {
            item_id: lastTargetId?.clickId,
            data: clickList,
            name: `${window.innerWidth},${window.innerHeight}`,
          });

          // Clear reset the clicks and prevent spam for a time
          preventSpam.current = new Date().getTime() + timeSpam * 1000;
          spamClicks.current = rageClicksQueue.current;
          clickEvents.current = [];
          rageClicksQueue.current = [];
          autoTriggerGA.current = undefined;
        }
      }, timeToKeepTrackingRageClicks * 1000);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('click', onClick);
    return () => {
      document.removeEventListener('click', onClick);
    };
  }, [onClick]);
};
export { RageClicks as default, RageClicks };
