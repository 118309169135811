import { SVG } from './SVG';
const IconHamburger = ({ color = '#4062BB' }) => (
  <SVG width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="20" height="2" rx="1" fill={color} />
    <rect y="5" width="20" height="2" rx="1" fill={color} />
    <rect y="10" width="14" height="2" rx="1" fill={color} />
  </SVG>
);

export { IconHamburger as default, IconHamburger };
