import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { getCondition } from '@medifind/interface';
import { setGA4GlobalParam } from '../../google';
import { migrateReduxSessionStorageFilterValue } from '../../utils';
import { setFilterValue } from '../filterValue';
const initialState = {
  display: 'Condition',
  name: 'condition',
  type: 'singleSelect',
  selected: null,
};
const storeName = 'condition';
export const useConditionFilter = create(
  devtools(
    persist(
      (set) => ({
        ...initialState,
        set,
        reset: () => set({ ...initialState }, false, { type: 'reset' }),
        getInitialState: () => ({ ...initialState }),
        setFilterValue: (v) => updateConditionFilterOption(v),
      }),
      {
        name: `${process.env.NX_APP}-${storeName}`,
        version: 0,
        onRehydrateStorage: () => (state) => {
          migrateReduxSessionStorageFilterValue({ state, key: 'condition' });
        },
      },
    ),
    {
      name: process.env.NX_APP,
      store: storeName,
    },
  ),
);

export const updateConditionFilterOption = async (option) => {
  // Condition and display may both be needed, and are basically duplicated, ideally we should be shifting to display
  if (option.condition && !option.display) option.display = option.condition;
  if (option.display && !option.condition) option.condition = option.display;
  setGA4GlobalParam({ projectId: option.value });

  if (option && process.env.NX_APP === 'MediFind-Pro') {
    if (option.accessRequest == null) {
      await getCondition(option.value).then((result) => {
        option.accessRequest = {
          accessDigitalPortal: result.accessDigitalPortal,
          accessKOLPortal: result.accessKOLPortal,
          accessNPOPortal: result.accessNPOPortal,
          accessOrgPortal: result.accessOrgPortal,
          accessPayorPortal: result.accessPayorPortal,
        };
      });
    }
  }
  setFilterValue(useConditionFilter, option);
};

export const clearConditionFilterOption = () => {
  useConditionFilter.setState({ selected: null }, false, {
    type: 'clearConditionFilterOption',
  });
  setGA4GlobalParam({ projectId: null });
};

export const selectConditionFilter = (state) => {
  return {
    condition: {
      ...state,
      value: state?.selected?.value || null,
      display: state?.selected?.display || null,
    },
  };
};
