import { useEffect } from 'react';
import { useMatchingRoute } from '@medifind/router';
import { isPrerender } from '@medifind/utils';

export const useLazyComponentPreload = (components, timeout = 10000) => {
  useEffect(
    () => {
      if (components && !isPrerender) {
        let timer;

        const handler = () => {
          timer = setTimeout(() => {
            (Array.isArray(components) ? components : [components]).forEach((component) => {
              try {
                component._init(component._payload);
              } catch (e) {}
            });
          }, timeout);
        };
        let loadEvent = false;
        if (document.readyState === 'complete') {
          handler();
        } else {
          loadEvent = true;
          window.addEventListener('load', handler, false);
        }

        return () => {
          if (loadEvent) document.removeEventListener('load', handler, false);
          clearTimeout(timer);
        };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    Array.isArray(components) ? components : [components],
  );
  return null;
};

export const useLazyRoutePreload = ({ paths, routes }, timeout = 10000) => {
  const components = useMatchingRoute({ paths: Array.isArray(paths) ? paths : [paths], routes }).map(({ component }) => component);
  useLazyComponentPreload(components, timeout);
};
