import { clientFetch } from '../clientFetch';

export const fetchProfile = () => {
  return clientFetch(`/user/profile`).then((r) => r.user);
};

export const putUserProfile = (body) => {
  return clientFetch(`/user/profile`, { method: 'PUT', body });
};

export const submitAddUser = (body) => {
  return clientFetch(`/entity/account/addUser`, { method: 'POST', body });
};

export const deleteUserAccount = (account, dns) => {
  return clientFetch(`/entity/account/deleteUser`, { method: 'POST', body: { account: account, dn: dns } });
};

export const deleteAccount = (pendingEmailVerification, password) => {
  return clientFetch(`/user/delete`, {
    method: 'DELETE',
    body: {
      pendingEmailVerification,
      password,
    },
  });
};

export const detachTeam = (profileManagementTeamId) => {
  return clientFetch(`/user/profile-management/users/detach`, { method: 'POST', body: { profileManagementTeamId } }).then((r) => r.user);
};

export const portalAccessRequest = (body) => {
  return clientFetch(`/portalaccessrequest`, { method: 'POST', body });
};
