import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { migrateReduxSessionStorageValue, sessionStorageMF } from '../utils';

const initialState = {
  action: null,
  data: null,
};

const storeName = 'authRedirect';
export const useAuthRedirect = create(
  devtools(
    persist(
      (set) => ({
        ...initialState,
        set,
        reset: () => set({ ...initialState }, false, { type: 'reset' }),
        getInitialState: () => ({ ...initialState }),
      }),
      {
        name: `${process.env.NX_APP}-${storeName}`,
        version: 0,
        storage: createJSONStorage(() => sessionStorageMF),
        onRehydrateStorage: () => (state) => {
          migrateReduxSessionStorageValue({ state, key: 'authRedirect' });
        },
      },
    ),
    {
      name: process.env.NX_APP,
      store: storeName,
    },
  ),
);

export const setAuthRedirect = (action, data, loginError) => {
  useAuthRedirect.setState({ action, data, loginError }, false, {
    type: 'setAuthRedirect',
  });
};

export const clearAuthRedirectStore = () => {
  useAuthRedirect.setState({ ...initialState }, false, {
    type: 'clearAuthRedirectStore',
  });
};
