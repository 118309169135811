import { v4 as uuidv4 } from 'uuid';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

const storeName = 'route';
export const useRouteStore = create(
  devtools((set) => ({ params: {}, pathParams: {}, queryParams: {}, routeUUID: '' }), {
    name: `${process.env.NX_APP}`,
    store: storeName,
  }),
);

export const setRouteParams = (routeParams) => {
  const routeUUID = uuidv4();
  useRouteStore.setState(
    {
      ...routeParams,
      routeUUID,
    },
    true,
    {
      type: 'setRouteParams',
    },
  );
};

export const setAdditionalRouteParams = (params, routeUUID) => {
  const routeStoreState = useRouteStore.getState();
  if (routeUUID === routeStoreState.routeUUID) {
    useRouteStore.setState({ ...routeStoreState, params: { ...routeStoreState.params, ...params } }, true, {
      type: 'setAdditionalRouteParams',
    });
  }
};

export const selectRouteParams = (state) => state.params;
