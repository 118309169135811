const routingEvents = { popstate: 'POP', popState: 'POP', pushState: 'PUSH', replaceState: 'REPLACE' };

const pageListenerName = `pageListener-${process.env.NX_APP}`;
export const listen = (callback) => {
  if (!window[pageListenerName]) {
    window[pageListenerName] = {
      previousPage: window.location.href,
      listeners: null,
    };
  }
  const routeChange = (event) => {
    if (routingEvents[event.type]) {
      const args = [
        event.arguments && event.arguments[2] ? event.arguments[2] : window.location.href,
        routingEvents[event.type],
        window[pageListenerName].previousPage,
      ];
      window[pageListenerName].listeners.forEach((x) => x(...args));
      window[pageListenerName].previousPage = window.location.href;
    }
  };
  if (!window[pageListenerName].listeners) {
    Object.keys(routingEvents).forEach((e) => window.addEventListener(e, routeChange));
    window[pageListenerName].listeners = [];
  }
  if (!window[pageListenerName].listeners.includes(callback)) window[pageListenerName].listeners.push(callback);

  return () => {
    // Unlisten function
    const index = window[pageListenerName].listeners?.indexOf(callback);
    if (index > -1) {
      window[pageListenerName].listeners.splice(index, 1);
    }
  };
};
