import { clientFetch } from '../clientFetch';
/*
 * Condition autocomplete
 */

let conditionAutocompleteController = null;

export const conditionAutocomplete = ({ input, showAll, size, projectIdFilter, codingIdFilter, useCancelToken = true }) => {
  if (!input) input = null;
  if (conditionAutocompleteController) {
    conditionAutocompleteController.abort();
  }
  conditionAutocompleteController = new AbortController();
  return clientFetch(
    `/autocomplete/coding`,
    { method: 'POST', body: { input, showAll, size, projectIdFilter, codingIdFilter } },
    useCancelToken ? conditionAutocompleteController : null,
  ).then((res) => {
    conditionAutocompleteController = null;
    return res;
  });
};
