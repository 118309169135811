import { useMemo } from 'react';
import { useRouter } from './useRouter';

export const useMatchingRoute = ({ paths = [], routes = [] }) => {
  const router = useRouter();
  return useMemo(() => {
    if (paths) return paths.map((path) => routes.find((route) => router.matcher(path, route.path)[0])).filter(Boolean);
    else return null;
  }, [paths]);
};
