import { useEffect, useRef, useState } from 'react';
import { Suspense } from 'react';
import classNames from 'classnames';
import { useRouter } from '@medifind/router';
import { isPrerender } from '@medifind/utils';
import { addPassiveListener } from './PassiveEventListener';
import styles from './MFLazyLoad.module.scss';

// MFLazyLoad loads children once scrolled into view.
// offset -> X amount of pixels from "below the fold" to load

const MFLazyLoad = ({
  children,
  checkOnLoad,
  checkOnChange,
  offset = 200,
  height = 1,
  resize = false,
  scroll = true,
  onContentVisible,
  noSuspense = false,
}) => {
  const [show, setShow] = useState(false);
  const ref = useRef(null);
  const router = useRouter();
  const positionCheck = () => {
    const pageBottom = window.scrollY + (window.innerHeight || document.documentElement.clientHeight);
    // Start loading new content at pixel offset of the bottom of the screen
    // if (height === 800) ref.current && console.log(show, ref.current.getBoundingClientRect());
    if (
      ref.current &&
      ref.current.getBoundingClientRect().height > 0 &&
      pageBottom + offset > ref.current.getBoundingClientRect().top + window.scrollY
    ) {
      setShow(true);
      onContentVisible && onContentVisible();
    }
  };
  useEffect(() => {
    if (!show) {
      const tabListener = (event) => {
        if (event.keyCode === 9 || event.key === 'Tab') {
          setShow(true);
        }
      };
      window.addEventListener('keydown', tabListener);

      return () => {
        window.removeEventListener('keydown', tabListener);
      };
    }
  }, [show]);
  // Check on scroll
  useEffect(() => {
    if (scroll && !show) {
      return addPassiveListener('scroll', positionCheck);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scroll, show]);

  // Check on resize
  useEffect(() => {
    if (resize && !show) {
      return addPassiveListener('resize', positionCheck);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resize, show]);

  // Check on data change
  // Check on page load
  // Check on url change
  useEffect(() => {
    if (!show) positionCheck();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.location.href, show, checkOnLoad, checkOnChange]);

  if (isPrerender || show) {
    if (noSuspense) return children;
    else
      return (
        <Suspense fallback={<div style={{ height }} ref={ref} className={classNames(styles['fallback'], 'fallback')} />}>
          {children}
        </Suspense>
      );
  } else {
    return (
      <div>
        <div style={{ height }} ref={ref} className={styles['fallback']}></div>
      </div>
    );
  }
};

export { MFLazyLoad as default, MFLazyLoad };
