import { Helmet } from 'react-helmet';
import { isPrerender } from '@medifind/utils';
import { checkForUndefined, htmlToLDJSON } from '@medifind/utils';

export const SEO = ({ title, description, canonical, images, noIndex, statusCode, redirectLocation, children }) => {
  checkForUndefined(canonical);
  checkForUndefined(redirectLocation);
  return (
    <Helmet defer={!isPrerender}>
      {title ? <title>{title}</title> : null}
      {description ? <meta name="description" content={htmlToLDJSON(description)} /> : null}
      {statusCode ? <meta name="prerender-status-code" content={statusCode} /> : null}
      {redirectLocation ? <meta name="prerender-redirect-location" content={redirectLocation} /> : null}
      {title ? <meta key="og:title" property="og:title" content={title} /> : null}
      {description ? <meta key="og:description" property="og:description" content={htmlToLDJSON(description)} /> : null}
      {images?.length > 0 ? <meta key="og:image" property="og:image" content={images[0].url} /> : null}
      {images?.length > 0 ? <meta key="og:image:width" property="og:image:width" content={images[0].width} /> : null}
      {images?.length > 0 ? <meta key="og:image:height" property="og:image:height" content={images[0].height} /> : null}
      {canonical ? <link rel="canonical" href={canonical} /> : null}
      {noIndex ? <meta name="googlebot" content="none" /> : <meta name="googlebot" content="index,follow,noarchive" />}
      {noIndex ? <meta name="robots" content="none" /> : <meta name="robots" content="index,follow,noarchive" />}
      {children}
    </Helmet>
  );
};
