import { useEffect, useRef, useState } from 'react';
import { useRouter } from '@medifind/router';
import styles from './ProgressBar.module.scss';

const ProgressBar = () => {
  const [show, setShow] = useState(false);
  const firstLoad = useRef(true);
  const router = useRouter();

  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false;
      return;
    }
    setShow(true);
    setTimeout(() => {
      setShow(false);
    }, 500);
  }, [router.wouterLocation]);

  return show ? (
    <div className={styles['progress']}>
      <div className={styles['bar']} role="bar"></div>
    </div>
  ) : null;
};
export { ProgressBar as default, ProgressBar };
