import { Suspense, lazy, useState } from 'react';
import { EventListener } from '@medifind/utils';
const Popup = lazy(() => import('./Popup'));
const Banner = lazy(() => import('./Banner'));
const PopupModal = lazy(() => import('./PopupModal'));

const Popups = () => {
  const [showComponent, setShowComponent] = useState({});
  return (
    <Suspense fallback={<div className="fallback" />}>
      <EventListener eventName={'popupModal'} onEvent={(e) => setShowComponent({ ...showComponent, popupModal: e })} />
      <EventListener eventName={'popup'} onEvent={(e) => setShowComponent({ ...showComponent, popup: e })} />
      <EventListener eventName={'banner'} onEvent={(e) => setShowComponent({ ...showComponent, banner: e })} />
      <EventListener eventName={'banner2'} onEvent={(e) => setShowComponent({ ...showComponent, banner2: e })} />
      {showComponent.popupModal && <PopupModal listenFor={'popupModal'} initEvent={showComponent.popupModal} />}
      {showComponent.popup && <Popup listenFor={'popup'} initEvent={showComponent.popup} />}
      {showComponent.banner && <Banner listenFor={'banner'} initEvent={showComponent.banner} />}
      {showComponent.banner2 && <Banner listenFor={'banner2'} initEvent={showComponent.banner2} />}
    </Suspense>
  );
};

export { Popups as default, Popups };
