/* eslint-disable class-methods-use-this */
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import { useRouter } from '@medifind/router';
import { toSlug } from '@medifind/router';
import { isPrerender } from '@medifind/utils';
const Layout = ({ children, className }) => {
  const router = useRouter();

  let currentRoute = router.location.pathname;
  if (currentRoute === '/') {
    currentRoute = 'page-home';
  } else {
    currentRoute = `page-${currentRoute.replace(/\//g, '-')}`;
  }
  const pageClass = `${toSlug(currentRoute)}`;
  const url = process.env.NX_LOCAL_HOST + router.location.pathname.replace(/\/$/, '');
  return (
    <>
      <Helmet defer={!isPrerender}>
        <meta property="og:url" content={url} />
        <link rel="canonical" href={url} />
      </Helmet>
      <div className={classNames('page', pageClass, className)}>{children}</div>
    </>
  );
};

export default Layout;
