import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

const storeName = 'header';
export const useHeaderStore = create(
  devtools(
    (set) => ({
      visible: null,
    }),
    {
      name: `${process.env.NX_APP}`,
      store: storeName,
    },
  ),
);

export const setHeaderVisibility = (visible) => {
  useHeaderStore.setState({ visible }, false, {
    type: 'setHeaderVisibility',
  });
};
